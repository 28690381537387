// colors
$blue: blue;

$xs: ( max:  767px );
$sm: ( min:  640px );
$md: ( min:  1025px );
$lg: ( min: 1200px );
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );

$below-md: ( max: map-get($md, min) - 1 );