@import "styles/_settings.scss";

.m-info__wrapper {
  padding: 170px 30px 50px;
  max-width: 650px;
  margin: 0 auto;
  font-family: 'Karla', sans-serif;
  text-align: center;
  h2 {
    font-size: 17px;
    font-family: 'Karla', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .05em;
    margin: 35px 0 0;
  }
  p {
    margin-bottom: 40px;
  }
  a {
    color: #000;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding: 0;
  }
}
