@import "styles/_settings.scss";
@import "styles/_mixins.scss";

.m-mobileNavToggler {

  position: absolute;
  right: 15px;
  top: 15px;

  @include breakpoint($md) {
    display: none;
  }

  span {
    display: block;
    position: relative;
    width: 25px;
    height: 2px;
    margin-bottom: 6px;

    background: $blue;

    z-index: 1;

    &:nth-child(2) {
      .s-menu-open & {
        display: none;
      }
    }

    &:first-child {
      .s-menu-open & {
        transform: rotate(45deg) translate(6px, 6px);
      }
    }

    &:last-child {
      .s-menu-open & {
        transform: rotate(-45deg) translate(0, 0);
      }
    }

  }
}