@import "styles/_settings.scss";

.m-index__wrapper {
  padding: 80px 30px 50px;
}
.m-index__image {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 10px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    font-family: sans-serif;
  }
}
