@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "styles/_settings.scss";
@import "styles/_mixins.scss";

.slick-slider {
  width: 100%;
  height: calc(100vh - 44px);
  //max-height: 1000px;
  padding: 65px 10px 90px;
  overflow: hidden;
  cursor: pointer;
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }
  @include breakpoint($md) {
    height: 100vh;
    padding: 120px 30px 120px;
  }
}
.slick-list,
.slick-track,
.slick-slide-wrapper,
.slick-slide > div {
  height: 100%;
}
.slick-slide-wrapper {
  display: flex!important;
  justify-content: center;
  align-self: flex-start;
  .slick-slide-image-wrapper {
    flex: 0 1 auto;
    margin: 0 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  &.slide-count-1 {

  }
  &.slide-count-2 {
    .slick-slide-image-wrapper {
      margin: 0 10px;
      &:first-of-type img {
        object-position: right;
      }
      &:last-of-type img {
        object-position: left;
      }
    }
  }
  &.slide-count-3 {

  }
  &.slide-count-4 {

  }
}
.layout-grid2x2 {
  &.slide-count-4 {
    min-height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    .slick-slide-image-wrapper {
      display: flex;
      width: calc(50% - 14px);
      flex-basis: calc(50% - 14px);
      justify-content: center;
      flex-direction: column;
      margin: 0 7px 14px;
      img {
        object-position: right;
        //padding: 5px;
        .s-orientation-portrait & {
          object-position: bottom!important;
        }
      }
      &:nth-of-type(2), &:nth-of-type(4) {
        .s-orientation-portrait & {
          img {
            object-position: top!important;
          }
        }
      }
      &:nth-of-type(3), &:nth-of-type(4) {
        img {
          object-position: left;
        }
      }
    }
  }
}
@include breakpoint($below-md) {
  .layout-grid2x2ToRow {
    &.slide-count-4 {
      min-height: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      .slick-slide-image-wrapper {
        display: flex;
        width: calc(50% - 14px);
        flex-basis: calc(50% - 14px);
        justify-content: center;
        flex-direction: column;
        margin: 0 7px 14px;
        img {
          object-position: right;
          .s-orientation-portrait & {
            object-position: bottom!important;
          }
        }
        &:nth-of-type(2), &:nth-of-type(4) {
          .s-orientation-portrait & {
            img {
              object-position: top!important;
            }
          }
        }
        &:nth-of-type(3), &:nth-of-type(4) {
          img {
            object-position: left;
          }
        }
      }
    }
  }
}
@include breakpoint($below-md) {
  .layout-stackedLandscapes {
    &.slick-slide-wrapper {
      flex-direction: column;
    }
    .slick-slide-image-wrapper {
      display: flex;
      flex-basis: calc(50% - 10px);
      margin: 0 0 10px!important;
      img {
        object-position: bottom!important;
      }
      &:last-of-type {
        img {
          object-position: top!important;
        }
      }
    }
  }
}
.slick-slide {
  img {
    height: 100%;
    max-height: 800px;
  }
}
.slick-slide-image-wrapper {
  //flex: 1 1 0;
}
.m-slides {
  .m-caption {
    position: absolute;
    bottom: 15px;
    padding: 0 20px;
    box-sizing: border-box;
    @include breakpoint($md) {
      bottom: 25px;
    }
  }
}
.m-slide__caption {
  display: none;
}