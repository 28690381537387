@import '_settings';
@import '_mixins';
$primary-color: #ccc;
@import '~loaders.css/src/animations/ball-triangle-trace.scss';

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}
.loader {
  @include align(true, true, fixed);
  left: calc(50% - 30px);
}
.ball-grid-beat {
  > div {
    border-radius: 0;
  }
}
.ball-triangle-path {
  > div {
    width: 70px;
    height: 70px;
  }
}
