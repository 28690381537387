@import "styles/_settings.scss";
@import "styles/_mixins.scss";
@import "styles/_loader.scss";

.App {
  margin: 0 auto;
}

.App-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //text-align: center;
  padding-top: 15px;
  z-index: 10;
  background-color: #fff;
  @include breakpoint($md) {
    height: 50px;
    &:hover {
      .page {
        opacity: 0;
      }
      .m-nav {
        opacity: 1;
      }
    }
  }
  h1 {
    font-size: 17px;
    font-family: 'Karla', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .05em;
    color: $blue;
    @include breakpoint($md) {
      padding-left: 15px;
    }
  }
  a {
    text-decoration: none;
  }
}

.page {
  @include align(false, true, absolute);
  top: 0;
  transition: opacity 100ms;
  h1 {
    color: #000;
  }
  display: none;
  @include breakpoint($md) {
    display: block;
  }
}

.name {
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 1;
}

.fade-enter, .fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active, .fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
  z-index: 0;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.stable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.m-nav {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(255,255,255,0.8);
  overflow: hidden;
  .s-menu-open & {
    opacity: 1;
    height: 100vh;
  }
  @include breakpoint($md) {
    height: inherit;
    background: transparent;
  }
}
.m-navWrapper {
  background-color: #fff;
  text-align: center;
  transition: opacity 100ms;
  padding-top: 12px;
  font-size: 15px;
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  letter-spacing: .05em;

  span {
    display: block;
    padding: 25px 30px 25px;
    border-bottom: 1px solid $blue;
  }
  a {
    text-transform: uppercase;
    color: $blue;
    &:hover {
      color: #000;
    }
  }

  @include breakpoint($md) {
    @include align(false, true, absolute);
    top: 0;

    span {
      display: inline;
      padding: 0 10px;
      border-bottom: none;
    }
    a {
      text-transform: none;
    }
  }
}
