@import "styles/_settings.scss";
@import "styles/_mixins.scss";

.buttons {
  display: none;
  position: absolute;
  right: 20px;
  top: 10px;
  @include breakpoint($md) {
    display: block;
  }
}
button {
  background: none;
  border: 0;
  font-size: 20px;
  cursor: pointer;
  outline: 0;
  svg {
    fill: $blue;
  }
}