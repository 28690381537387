@import "styles/_settings.scss";
@import "styles/_mixins.scss";

.m-prelude__wrapper {
  padding: 80px 30px 50px;
  position: relative;
  .m-caption {
    position: absolute;
    bottom: 15px;
    left: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.m-videoWrapperOuter {
  padding-top: 80px;
  max-width: 750px;
  margin: 0 auto;
  //@include align(true, true);
}
.m-videoWrapper {
  @include responsive-ratio(3,2);
}