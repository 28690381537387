@import "styles/_settings.scss";
@import "styles/_mixins.scss";

.m-set__wrapper {
  @include clearfix();
  padding: 110px 10px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include breakpoint($md) {
    padding: 80px 30px 50px;
  }
}
.m-set__image {
  display: inline-block;
  //width: 200px;
  height: calc(100% - 20px);
  margin: 10px 5px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    font-family: sans-serif;
  }
}
.m-set__comp {
  position: relative;
  width: 50%;
  height: 175px;
  display: flex;
  padding: 0 10px 40px;
  box-sizing: border-box;
  border: 1px solid transparent;
  @include breakpoint($sm) {
    width: 320px;
    //height: 180px;
    //padding: 0 15px 60px;
  }
  @include breakpoint($md) {
    width: 350px;
    height: 180px;
    padding: 0 30px 60px;
  }
  &:hover {
    //border: 1px solid #ccc;
    .m-set__id {
      display: block;
    }
  }
  a {
    display: inherit;
    margin: 0 auto;
  }
  &.slide-count-2 {
    .m-set__image {
      img {
        object-position: right;
      }
      &:last-of-type img {
        object-position: left;
      }
    }
  }
}
.m-set__id {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  @include align(false,true,absolute);
  border: 1px solid $blue;
  color: $blue;
  font-size: 12px;
  font-family: 'Karla', sans-serif;
  text-align: center;
  padding: 2px 4px 1px;
  @include breakpoint($sm) {
    //bottom: -20px;
  }
}

@media print {
  .m-set__wrapper {
    display: block;
  }
  .m-set__comp {
    position: relative;
    page-break-inside: avoid;
    width: 300px;
    height: 180px;
    //display: block;
    float: left;
    margin: 0 10px 10px;
  }
  .m-set__image {

  }
  .m-set__id {
    display: block;
    border: 0;
    bottom: -10px;
  }
}
