// proportional wrapper
@mixin responsive-ratio($x,$y, $pseudo: false) {
  position: relative;
  $padding: unquote( ( $y / $x ) * 100 + '%' );
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }

  > * {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

}


// font

// center



/*********************
BREAKPOINTS
*********************/
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
// another option: https://github.com/zellwk/mappy-breakpoints
// another: https://github.com/engageinteractive/core/blob/master/src/scss/utility/_mixins.scss

@mixin breakpoint($map) {
  $query: "";
  @if map-has-key($map, min) { $query: append($query, "(min-width: #{map-get($map, min)})") }
  @if map-has-key($map, min) and map-has-key($map, max) { $query: append($query, "and") }
  @if map-has-key($map, max) { $query: append($query, "(max-width: #{map-get($map, max)})") }
  @media screen and #{$query} { @content; }
}




// Hide only visually, but have it available for screenreaders
@mixin vh($focusable: false) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  @if $focusable {
    @include vh-focusable;
  }
}


// input placeholder
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

/*
	Retina images
	example:
	.element {
		@include retina {
			background-image: url(../img/background@2x.png);
		}
	}
*/

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 1.5dppx) {
    @content;
  }
}

@mixin align($vertical: true, $horizontal: false, $position: relative) {
  @if $position {
    position: $position;
  }
  @if $vertical {
    top: 50%;
  }
  @if $horizontal {
    left: 50%;
  }

  @if $vertical and $horizontal {
    transform: translateX(-50%) translateY(-50%);
  } @else if $vertical {
    transform: translateY(-50%);
  } @else {
    transform: translateX(-50%);
  }
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Clearfix mixin
@mixin clearfix {
  //*zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/*
Usage:

$start-color, $end-color, $orientation - vertical/horizontal/radial

.foo {
  @include background-gradient(red, black, 'vertical');
}
*/

@mixin background-gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}
